
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

@import url('/fonts/Proxima/stylesheet.css');

@media (max-width: 768px) {
  .share-card {
    width: 100%;
    min-height: calc(100svh - 45px - 28px);
    box-sizing: border-box;
    padding: 50px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .share-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #100D19;
  }

  .info {
    padding-top: 20px;
    display: flex;
    width: 204.579px;
    flex-direction: column;
    align-items: center;
    gap: 14.658px;
    display: flex;
    .tag {
      color: #FFF;
      text-align: center;
      font-family: "Proxima Nova";
      font-size: 24.973px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 2.199px;
    }
    .socials {
      display: flex;
      width: 147.151px;
      height: 34.662px;
      justify-content: center;
      align-items: center;
      gap: 29.077px;
      .twitter, .discord, .instagram {
        display: flex;
        svg {
          width: 35px;
          height: 35px;
          flex-shrink: 0;
        }
      }
    }
  }
  .download-btn {
    display: flex;
    svg {
      width: 140px;
    }
  }
}

@media (min-width: 768px) {
  .share-card {
    width: 25%;
    margin: 0 auto;
    max-height: 80%;
    box-sizing: border-box;
    padding: 50px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .share-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #100D19;
  }

  .info {
    padding-top: 20px;
    display: flex;
    width: 204.579px;
    flex-direction: column;
    align-items: center;
    gap: 14.658px;
    display: flex;
    .tag {
      color: #FFF;
      text-align: center;
      font-family: "Proxima Nova";
      font-size: 24.973px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 2.199px;
    }
    .socials {
      display: flex;
      width: 147.151px;
      height: 34.662px;
      justify-content: center;
      align-items: center;
      gap: 29.077px;
      .twitter, .discord, .instagram {
        display: flex;
        svg {
          width: 35px;
          height: 35px;
          flex-shrink: 0;
        }
      }
    }
  }
  .download-btn {
    display: flex;
    svg {
      width: 140px;
    }
  }
}

// ...

.top {
  margin-top: 47px;
  @include sm-mobile {
    margin-bottom: 30px;
  }
}

.middle {
  padding: 20px;
  display: grid;
  align-items: center;
  grid-template-columns: 1.5fr 2fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'flip .'
    'left right';

  @include sm-mobile {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
    grid-template-areas:
      'flip'
      'left'
      'right';
    border: none;
  }

  .grid-left {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-right: 20px;
    grid-area: left;

    @include sm-mobile {
      padding-right: 0;
    }
  }
  .grid-right {
    grid-area: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0;

    .title {
      font-size: 24px;
      text-align: center;

      &::before {
        content: 'Select';
        text-transform: uppercase;
        font-size: 2em;
        line-height: 1.2;
        font-weight: 900;
        white-space: pre-wrap;
        display: block;
      }
      &::after {
        content: 'one of the following';
        text-transform: uppercase;
        white-space: pre-wrap;
        display: block;
      }
      @include md-tablet {
        font-size: 30px;
        text-align: left;
      }
    }

    @include md-tablet {
      margin-left: 20px;
    }
  }
}

.card-item-failed {
  background-color: darkgray !important;
  width: 100%;
  color: black;
  //height: 100%;
  //max-width: 260px;

  item-image {
    width: 100%;
  }
  .item-image {
    background-color: white !important;
    background-position: center center !important;
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important;
  }
  .item-image::before {
    content: '';
    display: block;
    width: 1px;
    height: 0;
    padding-bottom: calc(100% / (16 / 9));
  }

  .item-image::after {
    content: '';
    display: block;
  }

  .item-footer {
    width: 100%;
    padding: 0 0 0 13px;
    box-sizing: border-box;
    height: 60px;
    background-color: white;
    display: flex;
    justify-content: space-between;

    align-items: center;

    .item-description {
      font-size: 14px;
      font-family: 'brandon-grotesque';
      font-weight: 300;
    }

    .item-qr {
      width: 50px;
      height: 50px;

      & > * {
        width: 100%;
        height: auto;
      }
    }
  }
}
